.canvas{
    background-color: rgb(25, 25, 37);
    margin-top: 10px;
    opacity: 0.95;
    position: relative;
    width:100%;
}
.canvas:hover{
    opacity:1;
}

.headCanvas{
    position:absolute;
    z-Index:5;
    top:40%; 
    left:1%;
    background-Color:blue;
    color:white;
    border-radius: 20px;
    border: 2px solid white;
    }
.headCanvas:hover{
    opacity: 0;
}
.headCanvas h2{
    padding: 20px;
    line-height: 1.7em;
}
.button{
background-color:blue;
color:white;
width:150px;
padding:10px;
margin:10px auto; 
box-sizing: border-box;
border:2px solid white;
border-radius:10px;

}
.button:hover{
    background-color: rgb(65, 195, 228);
}
p{
    padding: 40px;
    line-height: 1.7rem;
    font-size: 1.2em;
    background-size: contain;
}

h5{
    font-size: 30px;
    margin-top:20%;
    text-align: center;
}


.rtlsIntro{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-top: 100px;
}

.intro{
    display: flex;
    flex-direction: column;
    width:50%;
    align-items: center;

}
.layers{
    width:48%;
    justify-content: center;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 1fr);
}
.layersRows{
    width: 100%;
    border:1px solid black;
    height:90px;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 2px;
    align-items: center;
    color: white;
    padding: 5px;
    transition: width 500ms ease-in-out;
    transition: background-color 500ms ease-in-out;
    background-color: blue;

}
.layersRows:hover{
    width: 103%;
    background-color: rgb(0,0,200);
}

.softwareIntro{
    display: flex;
    flex-direction: row;
    height:800px;
    align-items: center;
    text-align: center;
}
.softwareIntro .imageContainer{
width:50%;
background-image: "/softwareApplications.png";
background-size: contain ;
background-repeat: no-repeat;
}


.softwareLayers{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap:5px;
}
.softwareRows{
    display: flex;
    flex-direction: row;
    width:100%;
    color:black;
    text-align: left;
}
.hardwareIntro{
    display:flex;
    flex-direction: row;
    align-items: center;
    min-height:800px;
}
.hardwareIntro .tags{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: 1fr fr;
    align-items: center;
    align-content: center;
    width:49%;
    background-color: rgb(48, 45, 45);
    border-radius: 25px;
    margin-bottom: 50px;

}
.hardwareIntro .readersHome{
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: 1fr fr;
    width:49%;
    background-color: rgb(48, 45, 45);
    border-radius: 25px;
}
.hardwareIntro .tags img{
    max-width:300px;
    border-left: 2px solid white;
}
.hardwareIntro .readersHome img{
    border-right: 2px solid white;
    max-width:300px;
}

.rtlsBenefitsBackground{
   background-size:150%;
   background-position:center;
   transition: background 500ms ease-in-out;
}
.rtlsBenefitsBackground:hover{
    background-size:170%;
 }



@media screen and (max-width:800px){
    .rtlsBenefitsBackground{
        background-size: 0%;
     }
     .rtlsBenefitsBackground:hover{
        background-size: 0%;
     }
    .nav-logo{
        max-width: 100px;
        max-height: 100px;
        z-index: 999;
        position: relative;
      }

.softwareIntro{
    display: flex;
    flex-direction: column;
    }
.softwareIntro .imageContainerHome{
    width:100%;
    background-position: center;
    background-size: 400px;
    
}
.softwareIntro .textContainerHome{
    display: flex;
    flex-direction: column;
    background: rgb(244,242,252);
    background: radial-gradient(circle, rgba(244,242,252,1) 50%, rgba(134,235,255,1) 100%);
}
.hardwareIntro {
    display: flex;
    flex-direction: column;
    height: 500px;
}
.hardwareIntro .tags{
    width:100%;
    }
.hardwareIntro .readersHome{
        width:100%;
        }
}


.textImage .textContainerHome h2{
    text-align: center;
}
.textImage .imageContainerHome{
    width:50%;
    text-align: center;
    justify-items: center;
    text-justify: center;

}
.textImage .textContainerHome{
    width:50%;

}
.textImage .imageContainerHome .imageHome{
    max-width: 80%;
    max-height: 80%;
    justify-self: center;
 }


  @media screen and (max-width: 800px) {
    .nav-logo{
        max-width: 100px;
        max-height: 100px;
        z-index: 999;
        position: relative;
      }

    .textImage .textContainerHome{
        width:100%;
    }
.textImage .imageContainerHome{
        width:100%;
    }

.rtlsIntro{
        width:100%;
        display: block;
        padding: 0;
        margin: 0;
        align-content: center;

            }
.intro{
        width:100%;
        display: block;
        padding: 0;
        margin: 0;
        justify-self: left;
    }
.intro p{
        width:100%;
        margin: 0;
        justify-content: center;
        text-align: left;
        padding: 15px;

    }
.layers{
        width:90%;
        justify-content: center;
        justify-items: center;
        align-content: center;
        text-align: center;
        align-items: center;
    }
}



.textAnimation{
    display: flex;
    flex-direction: row;
    width:100%;
    min-height:100vh;
    align-items: center;

}


.textAnimation .intro{
    width:50%;

}
.textAnimation .animation{
    width:50%;
    height:100vh;
}

@media screen and (max-width:800px){
    .textAnimation{
        flex-direction: column;
    }
    .textAnimation .intro{
        width:100%;
        padding-top: 50px;
        height:70vh;
    }
    .textAnimation .animation{
        width:100%;
        height:70vh;
    }
}

























