.readersHeading{
    display: flex;
    width:100%;
    height:500px;
    background-image: url("./images/readers.png");
    background-color: black;
    color: white;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 10px;
}

.textImageReaders{
    display: flex;
    flex-direction: row;
    width:100%;
    height:500px;
    align-items:center;
    padding:10px;
    justify-content: center;
}
.textImageReaders .imageContainerReaders{
    width:50%;
}
.textImageReaders .textContainerReaders{
    width:50%;


}
.textImageReaders .imageContainerReaders .image{
    width: 80%;

 }


 @media screen and (max-width:800px){
     .textImageReaders{
         display: flex;
         flex-direction: column;
         height: 800px;

     }
     .textImageReaders .textContainerReaders{
         width: 90%;
     }
     .textImageReaders .imageContainerReaders{
         width:90%;
         margin-bottom: 100px;

     }
 }

 .tagsInfo{
    margin-top:0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.tagsInfo .colTags{
display: flex;
flex-direction: column;
width: 30%;   
border:1px solid black;
align-items: center;
text-align: left;
min-height:700px;
text-justify: center;
margin: 10px 20px;
box-shadow: grey 5px 5px;

}
.tagsInfo .colTags h3{
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid black;
    width:100%;
    
}

.tagsInfo .imageContainerTags{
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:100%;
    height:300px;
    background-color: rgb(247, 243, 237);

}
.colTags .imageContainerTags img{
    position: relative;
    max-width:80%;
    max-height:80%;
    margin-top: 10px;
    transform: scale(1);
    transition: 300ms ease-in-out;

}
.imageContainerTags img:hover{
    transform: scale(1.2);
}


.colTags p{
    border-top: black 1px solid;
}

.tagsHeading{
    display: flex;
    width:100%;
    height:500px;
    background-image: url("./images/westigateTags.png");
    background-color: black;
    color: white;
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
    margin-top: 10px;
}

.MenuTags{
    align-items: center;
    text-align: center;
    justify-content: space-around;
    width: 100%;
    padding-top: 5%;
  }
  
  .MenuTags ul{
    padding-top: 150px;
    display: flex;
    justify-content: center;
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .MenuTags ul li{
    list-style: none;
    margin: 0px 30px;
    cursor: pointer;
  }
  
  .MenuTags ul li i{
    position: relative; 
    display: block;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 63px;
    background: #333;
    border-radius: 50%;
    font-size: 30px;
    color: #666;
    transition: .5s;
  }
  .MenuTags ul li i::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #ffee10;
    transition: .5s;
    transform: scale(.9);
    z-index: -1;
  }
  
  .MenuTags ul li i:hover::before {
    transform: scale(1.1);
    box-shadow: 0 0 15px #ffee10;
  }
  
  .MenuTags ul li i:hover {
    color: #ffee10;
    box-shadow: 0 0 5px #ffee10;
    text-shadow: 0 0 5px #ffee10;
  }

@media screen and (max-width:800px){
     .tagsInfo {
        display:flex;
        flex-direction: column;
    }
    .tagsInfo .colTags{
        width: 97%;
        min-height: 500px;
    }
    .colTags p{
        padding: 15px;
    }
    .MenuTags ul li{
        list-style: none;
        margin: 0px 10px;
      }
      
}
