/*
=============== 
Variables
===============
*/
/*
=============== 
Global Styles
===============
*/
:root{
  --turk: rgb(50, 218, 240); 
}



*,
::after,
::before {
  margin: 0 0.1%;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Roboto Slab', serif, sans-serif, serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  font-size: 0.875rem;
  align-content: center;
  align-items:center;
  justify-content: center;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}

@media screen and (max-width: 800px){
  h1 {
    font-size: 2rem;
  }
  h2{
    font-size: 1.7rem;
  }
}




@media screen and (min-width: 800px) {
  .nav-logo{
    max-width: 100px;
    max-height: 100px;
    z-index: 999;
    position: relative;
  }
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4{
    line-height: 1;
  }
  h5{
    font-size: 1rem;
  }
}
/*  global classes */

/* section */
.underline {
  width: 10rem;
  height: 0.25rem;
  background: var(--turk);
  margin:15px auto;
 
}

.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .nav-logo{
    max-width: 100px;
    max-height: 100px;
    z-index: 999;
    position: relative;
  }
  .section {
    width: 95vw;
  }
}

/*
=============== 
Stripe Styles
===============
*/

.nav {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background:white;
  z-index: 5;
  border-bottom: 5px solid black;
}


.nav-center {
  width: 200vw;
  max-width: var(--max-width);

}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.btn {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: grey;
  color: black;
  background: var(--clr-black);
  cursor: pointer;
  transition: var(--transition);

}
.btn:hover {
  background: var(--clr-grey-5);
}
.nav-links {
  display: none;
  width: 80%;
  align-items: center;
}

.nav-links li i{
  margin-right: 5px;
}


/* nav media query */
@media screen and (min-width: 800px) {
  .nav-logo{
    max-width: 100px;
    max-height: 100px;
    z-index: 999;
    position: relative;
  }
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    justify-content: center;

  }
  .toggle-btn {
    display: none;
  }

  .nav-links {
    display: block;
    justify-self: end;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 100%;
    align-items: center;
  }
  .nav-logo{
    max-width: 80px;
    max-width: 80px;
    z-index: 999;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 100%;
    background:transparent;
    border-color:transparent;
    font-size: 1rem;
    color: black;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Roboto Slab', serif, sans-serif, serif, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 8rem;

  }
}


/* sidebar */
.sidebar-wrapper {
  position: absolute;
  top: 0;
  right: 0px;
  width: 50%;
  height: 100%;
  display: flex;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: 300ms ease-in-out;
  transform: scale(0);

}


.sidebar-wrapper.show {

  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 100vh;
  max-width: var(--fixed-width);
  background: blue;
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
  color: white;
}
.close-btn {
  font-size: 2rem;
  background:  rgb(50, 218, 240);
  border-color: white;
  color: var(--clr-grey-5);
  position: absolute;
  top: 4rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  align-items: center;
  place-content: center;
  place-items: center;
}
.sidebar article h4{
  cursor: pointer;
  font-size: large;
}
.sidebar article h4:hover{
  color:  rgb(50, 218, 200);
}

.sidebar-sublinks {
  display: flex;
  flex-direction: column;
  row-gap: 0.1rem;
  font-size: 12px;
}
.sidebar-sublinks li{
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.3);
  padding-left: 15%;
}
.sidebar-sublinks li:hover{
  border-bottom: 1px solid  rgb(50, 218, 240);
}



@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }
}




/* links */
.submenu {
  background: rgb(11, 2, 32);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
  color: white;
}
.submenu::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid white;
  border-right: 5px solid white;
  border-bottom: 5px solid var(--clr-black);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: flex;
  flex-direction: row;
}
/* .col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
} */
.submenu h4 {
  margin-bottom: 1rem;
}
.submenu-center a {
  width: 10rem;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.submenu-center a:hover{
  border-bottom: 1px solid rgba(50, 218, 240, 1) ;
}

.footer{
  padding: 40px;
  display: flex;
  flex-direction: row;
  background-color: black;
  color:white;
  width:100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
}
.footer .links{
  width:50%;
  align-items: center;
  text-align: left;
}
.links li{
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  
}
.links1{
  width:50%;
}
.links2{
  width:50%;
}


.iconsFooter{
  width:50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 800px){
  .footer{
    flex-direction: column;
  }
  .footer .links{
    width:100%;

  }
  .iconsFooter{
    width:100%;
    padding-top: 50px;
  }
}

.textImage{
  display: flex;
  flex-direction: row;
  min-height:500px;
  align-items:center;
  align-content: center;
  justify-items: center;
  text-justify: center;
  padding-right:100px;
}

.imageContainer{
  width:50%;
  text-align: center;
  justify-items: center;
  text-justify: center;
}

.textContainer {
  width:50%;
  position: relative;
  text-align: left;

}

.imageContainer .image{
  max-width: 100%;
  max-height: 100%;
  justify-self: center;
}

.headingSection{
  justify-content: center;
  text-align: center;
  margin-top: 100px;
}


@media screen and (max-width:800px){
  .textImage{
    display: flex;
    flex-direction: column;
    padding: 30px;
    
  }
  .imageContainer{
    width:100%;
  }
  .textContainer{
    width:100%;
  }
}


.pageHeading{
  display: flex;
  width:100%;
  height:500px;
  color: white;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px auto;
  transition: 1s ease-in-out;
 }


.imageContainerCircle{
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width:100px;
  height:100px;
  background-color: rgb(247, 243, 237);
  border-radius: 50%;
  left:25%;
  box-shadow: rgb(11, 219, 247) 5px 5px;

}

.imageContainerCircle img{
  position: absolute;
  top:0;
  left:10;
  right:0;
  bottom: 0;
  max-width:90%;
  max-height:90%;
  margin-top: 10px;
  border-radius: 50%;
  align-items: center;

}



.rtlsBenefits{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2%;
  background-color:rgb(0, 0, 0, 0.8);
  color: white;
  transition: background 500ms ease-in-out;
}

.colBenefits{
width:20%;
text-align: center;
}


.rtlsBenefitsBackground{
background-Image:"/aboutUs.png";
background-repeat: no-repeat;
} 
@media screen and (max-width:800){
  .rtlsBenefitsBackground{
    background: none;
  }
}

.introductionText{
  align-items: center;
  justify-content: center;
  padding-left: 20%;
  padding-right: 20%;
  margin-top: 150px;
}
.introductionText video{
  width:100%;
}

.implementCards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100vh;
}

.implementCards .box{
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  background-color: rgb(11, 2, 32);
  min-height: 50px;
  width:30%;
}


@media screen and (max-width:800px){
  .rtlsBenefits{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .rtlsBenefits .colBenefits{
    width:100%;
  }
  .introductionText{
    padding-left: 5px;
    padding-right: 5px;
  }
}

  
.scroll {
  opacity: 0.5;
  background-color: blue;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  left: 20px;
  border-radius: 5px;
  border: grey;
  align-items: center;
}
.scroll:hover{
  opacity: 1;
}

.scroll a{
  color: white;
}

.arrow-up {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -5px;
}

.vid-wrapper {
justify-content: center;
text-align: center;
width:50%;
height: 50vh;
margin:0 auto;
}



@media screen and (max-width:800px){
  .vid-wrapper {
    width:100%;
    }
}