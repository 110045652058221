.pageHeadingConstruction{
    background-image:url("./images/construction.png");
    background-size:110%;
    background-position: center;
    transition: background 1s ease-in-out;
  }
.pageHeadingConstruction:hover{
  background-size: 120%;
}

.pageHeadingFactory{
  background-size:100%;
  background-position: center;
  transition: background 1s ease-in-out;
  background-image: url("./images/factoryHeadingPic.jpg");
}
.pageHeadingFactory:hover{
  background-size: 110%;
}


.pageHeadingConstruction div{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.4);
  transition:800ms ease-in-out;
}

.pageHeadingConstruction div:hover{
  background-color:rgba(0, 0, 0, 0.8);

}
.pageHeadingFactory div{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100%;
  background-color:rgba(0, 0, 0, 0.4);
  transition:800ms ease-in-out;
}

.pageHeadingFactory div:hover{
  background-color:rgba(0, 0, 0, 0.8);

}



.useCases{
  display:flex;
  flex-Direction:row;
  justify-Content:center;
  min-height:500px;
  width:100%;
  align-Content:center;
  text-Align:center
}

.useCasesDiv{
  background-Size:cover;
  height:500px;
  background-position: center;
  border:10px solid black;
}

.factoryApplicationsIcon{
  width:200px;
  height:200px;
  left:50%;
}




@media screen and (max-width:800px){
.useCases {
  display: flex;
  flex-direction: column;
}
.pageHeadingConstruction, .pageHeadingFactory{
  background-size: cover;
}
.pageHeadingConstruction:hover, .pageHeadingFactory:hover{
  background-size: cover;
}
.factoryApplicationsIcon{
  left:25%;
  margin-bottom: 50px;

}
}


.textImage ul{
  width: 50%;
  align-items: center;
  justify-self: center;
}

.textImage ul li{
  border-bottom: rgba(255, 255, 255, 0.4) 1px solid;
  width:90%;
  text-justify: left;
  margin: 5px auto;
  color: white;
  list-style: square;
}

.sectorBenefitsImages{
  width:50%;
  text-align: right;
}
.sectorBenefitsImages img{
width:700px;
}

@media screen and (max-width:800px){
  .sectorBenefitsImages{
    width:100%;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
  }
  .sectorBenefitsImages img{
    width:150px;
    height:200px;
    
  }

  .textImage ul, .textImage ul li{
    width:100%;
  }
  
}




.rtls-an{
  display: flex;
  flex-wrap: wrap;
  background-image: url("./images/black-pattern.jpg");
  background-position: center;
  background-size: cover;
  min-height:500px;
  justify-content: center;
  align-content: center;
  text-align: left;
  margin-top: 10px;
}

.rtls-an h2{
  margin-left: 5%;
  width: 90%;
  color: white;
  
}
.span-words:before{
      position: absolute;
      content: '';
      color: #6EE2E4;
      animation: anim infinite 10s;
  }
@keyframes anim{
  0%{content: 'care process';}
  18%{content: "patients' experience";}
  36%{content: 'staff efficiency';}
  54%{content: 'asset management';} 
  75%{content: 'security';}
  99%{content: 'Safety';}
}


.healthcareApps{
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 59% 95%, 50% 100%, 41% 95%, 0 95%);
  min-Height:100px;
 padding-Top:60px;
 padding-Bottom:60px;
 border:1px solid rgba(0,0,0,0.1);
 align-Items:center; 
 text-Align:center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  border: 1px solid black;
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
